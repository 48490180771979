<template>
  <!-- 报告单查询 -->
  <div class="inspection-check">
    <!-- 切换就诊人 -->
    <SwitchPeople style="margin-bottom:0.5rem" />
    <!-- 选择栏 -->
    <van-tabs v-model="active" animated @change="showClick">
      <van-tab
        v-for="(item, index) in ['检查单', '检验单']"
        :key="index"
        :title="item"
      >
        <Scroll class="region-scroll" ref="scroll">
          <div class="reportmodular">
            <!-- 检查单 -->
            <div v-show="active == 0">
              <!-- 检查单内容 -->
              <van-empty
                v-if="inspect.length == 0"
                style="height:calc(100vh - 8.16rem)"
                image="search"
                description="暂无检查单"
              />
              <div v-else>
                <div
                  v-for="(item, index) in inspect"
                  :key="index"
                  class="test2"
                  @click="
                    $router.push({ name: 'checkList', params: { obj: item } })
                  "
                >
                  <div class="content">
                    <span class="testflexone">患者姓名:</span>
                    <span>
                      {{
                        item.name == undefined ||
                        item.name == null ||
                        item.name == ''
                          ? '---'
                          : item.name
                      }}
                    </span>
                  </div>
                  <div class="content">
                    <span class="testflexone">检查名称：</span>
                    <span>
                      {{
                        item.jcxm == undefined ||
                        item.jcxm == null ||
                        item.jcxm == ''
                          ? '---'
                          : item.jcxm
                      }}
                    </span>
                  </div>
                  <div class="content">
                    <span class="testflexone">检查科室：</span>
                    <span>
                      {{
                        item.kdksmc == undefined ||
                        item.kdksmc == null ||
                        item.kdksmc == ''
                          ? '---'
                          : item.kdksmc
                      }}
                    </span>
                  </div>
                  <div class="content">
                    <span class="testflexone">检查医生：</span>
                    <span>
                      {{
                        item.kdysmc == undefined ||
                        item.kdysmc == null ||
                        item.kdysmc == ''
                          ? '---'
                          : item.kdysmc
                      }}
                    </span>
                  </div>
                  <div class="content">
                    <span class="testflexone">送检时间：</span>
                    <span>
                      {{
                        item.kdsj == undefined ||
                        item.kdsj == null ||
                        item.kdsj == ''
                          ? '---'
                          : item.kdsj
                      }}
                    </span>
                  </div>
                  <div class="content">
                    <span class="testflexone">结果时间：</span>
                    <span>
                      {{
                        item.book_time == undefined ||
                        item.book_time == null ||
                        item.book_time == ''
                          ? '---'
                          : item.book_time
                      }}
                    </span>
                  </div>
                  <!-- 右箭头 -->
                  <div class="rightarrow">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-youjiantou1-copy"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <!-- 检验单 -->
            <div v-show="active == 1">
              <van-empty
                v-if="test.length == 0"
                style="height:calc(100vh - 8.16rem)"
                image="search"
                description="暂无检验单"
              />
              <div v-else>
                <div
                  v-for="(item, index) in test"
                  :key="index"
                  class="test2"
                  @click="testdetail(index)"
                >
                  <div class="content">
                    <span class="testflexone">患者姓名:</span>
                    <span>
                      {{
                        item.name == undefined ||
                        item.name == null ||
                        item.name == ''
                          ? '---'
                          : item.name
                      }}
                    </span>
                  </div>
                  <div class="content">
                    <span class="testflexone">检验名称：</span>
                    <span>
                      {{
                        item.sampletype == undefined ||
                        item.sampletype == null ||
                        item.sampletype == ''
                          ? '---'
                          : item.sampletype
                      }}
                    </span>
                  </div>
                  <div class="content">
                    <span class="testflexone">检验科室：</span>
                    <span>
                      {{
                        item.depname == undefined ||
                        item.depname == null ||
                        item.depname == ''
                          ? '---'
                          : item.depname
                      }}
                    </span>
                  </div>
                  <div class="content">
                    <span class="testflexone">报告医生：</span>
                    <span>
                      {{
                        item.reportdoc == undefined ||
                        item.reportdoc == null ||
                        item.reportdoc == ''
                          ? '---'
                          : item.reportdoc
                      }}
                    </span>
                  </div>
                  <!-- <div class="content">
                    <span class="testflexone">开单时间：</span>
                    <span>{{item.receivetime == undefined || item.receivetime == null || item.receivetime == '' ? '---' : item.receivetime}}</span>
                  </div> -->
                  <div class="content">
                    <span class="testflexone">报告时间：</span>
                    <span>
                      {{
                        item.reporttime == undefined ||
                        item.reporttime == null ||
                        item.reporttime == ''
                          ? '---'
                          : item.reporttime
                      }}
                    </span>
                  </div>
                  <!-- 右箭头 -->
                  <div class="rightarrow">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-youjiantou1-copy"></use>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Scroll>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { inspectQuery, checkQuery } from '@/network/service.js'
import SwitchPeople from '@/components/common/switchPeople/switchPeople'
import Scroll from '@/components/content/scroll/BScroll'
import { newsdate } from '@/common/utils.js'
export default {
  components: {
    SwitchPeople,
    Scroll,
  },
  data() {
    return {
      active: 1,
      show: true,
      lists: [{ name: '检查单' }, { name: '检验单' }],
      //检验
      test: [],
      testlist: [],
      //检查
      inspect: [],
    }
  },
  activated() {
    if (this.$store.state.adopt) {
      this.active = 1
      this.show = true
      this.test = []
      this.inspect = []
      // this.checkQueryPost()
      this.inspectQueryPost()
    }
  },
  deactivated() {
    this.$store.state.adopt = false
  },
  methods: {
    //检查单
    async checkQueryPost() {
      let res = await checkQuery({
        seq: this.$store.state.seq,
        name: this.$store.state.cardList[0].name,
        id_no: this.$store.state.cardList[0].idNo,
        source: 1,
        type: 1,
      })
      if (res.code == 0) {
        let detail = res.data.root.body.detail
        // detail = detail.filter(item => item.kdsj.slice(0, 10).split('-').join('') * 1 > 20210101)
        this.inspect = detail
        this.$nextTick(() => {
          this.$refs.scroll[this.active].refresh()
        })
      } else {
        this.$toast(res.msg)
      }
    },
    //检验单
    async inspectQueryPost() {
      let res = await inspectQuery({
        seq: this.$store.state.seq,
        med_id: this.$store.state.med_id,
        begin_date: newsdate()[0],
        end_date: newsdate()[1],
        name: this.$store.state.cardList[0].name,
      })
      if (res.code == 0) {
        this.test = res.data.root.body.detail
        // let detail = res.data.root.body.detail
        // detail = detail.filter(item => item.report_time.slice(0, 10).split('-').join('') * 1 > 20210101)
        // let obj = {}
        // detail.forEach(item => {
        //   if (!obj[item.jcxmdm]) {
        //     obj[item.jcxmdm] = true
        //     item.list = []
        //     item.list.push(JSON.parse(JSON.stringify(item)))
        //     this.test.push(item)
        //   } else {
        //     this.test.forEach(itemOne => {
        //       if (item.jcxmdm == itemOne.jcxmdm) {
        //         itemOne.list.push(item)
        //       }
        //     })
        //   }
        // })
        this.$nextTick(() => {
          this.$refs.scroll[0].refresh()
        })
      } else {
        this.$toast(res.msg)
      }
    },
    testdetail(index) {
      this.$router.push({
        name: 'inspectionSheet',
        params: { item: this.test[index] },
      })
    },
    showClick() {
      if (this.active == 1 && this.show) {
        this.show = false
        this.inspectQueryPost()
      } else if (this.active == 0) {
        this.$toast('暂未开通该功能')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.region-scroll {
  height: calc(100vh - 8.16rem);
}
.inspection-check {
  height: 100vh;
  width: 100%;
  background-color: #f5f5f5;
  .selectionbar {
    display: flex;
    position: relative;
    flex-direction: row;
    height: 2.5rem;
    width: 100vw;
    background-color: @cardColor;
    .dottedline {
      width: 0.0625rem;
      height: 3rem;
      position: absolute;
      margin-left: 50vw;
      border-right: 0.0625rem dashed black;
    }
    .test {
      height: 3rem;
      width: 50vw;
      text-align: center;
      line-height: 3rem;
    }
  }
  .reportmodular {
    width: 100%;
    background-color: @cardColor;
    margin-top: 0.5rem;
    .test2 {
      position: relative;
      width: 100vw;
      line-height: 1.8rem;
      padding: 1rem 0;
      border-bottom: 0.5rem solid rgba(243, 243, 243, 0.842);
      .content {
        display: flex;
        background-color: @cardColor;
        .testflexone {
          width: 6.25rem;
          padding-left: 1.25rem;
        }
      }
    }
    .rightarrow {
      position: absolute;
      right: 0.3rem;
      top: 5rem;
      width: 10vw;
    }
  }
}
</style>
